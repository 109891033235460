import React, { createContext, useState } from "react";

export const AppContext = createContext({
  settings: {},
  setSettings: null,
});

const AppProvider = ({ children }) => {
  const [settings, setSettings] = useState(null);

  return (
    <AppContext.Provider value={{ settings, setSettings }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
